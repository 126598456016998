import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import {Lazyload} from 'vant';
import i18n from './utils/lang';
import 'vant/lib/index.less';
import '@/assets/css/common.css';
import 'lib-flexible/flexible'
import Store from './store';
import {Dialog,showConfirmDialog,Calendar, Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import utils from './utils/utils';
// import VueSocketio from 'vue-socket.io';

Vue.config.productionTip = false
// 注册全局事件总线
// @see https://blog.51cto.com/knifeedge/5140699
// Vue.prototype.$EventBus = new Vue();

Vue.use(Vant).use(Lazyload);
Vue.use(Dialog,showConfirmDialog,Calendar);

Locale.use('en-US', enUS);


Vue.filter('formatImg', utils.formatImg);


new Vue({
  render: h => h(App),
  store: Store,
  i18n,
  router
}).$mount('#app')
